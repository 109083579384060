import * as yup from "yup";
import i18next from "i18next";
import { NipValidator } from "utils";
import { regexps } from "utils/constants";
import { TestContext } from 'yup';


const registrationPurposeTest = {
  name: 'atLeastOneCheckbox',
  message: "Pole wymagane", // "Select at least one option"
  test: function(this: yup.TestContext): boolean {
    console.log(this.parent);
    const checkboxes = [
      this.parent.registrationPurpose_checkSingleGtin,
      this.parent.registrationPurpose_checkMultipleGtins,
      this.parent.registrationPurpose_useSearch,
      this.parent.registrationPurpose_downloadDatabase,
      this.parent.registrationPurpose_checkMyGtins,
      this.parent.registrationPurpose_generateCodeQR,
    ];
    return checkboxes.some(Boolean);
  }
};

const sharedFields = {
  companyProfile: yup.string().required("Pole wymagane"),
  registrationPurpose_checkSingleGtin: yup.boolean(),
  registrationPurpose_checkMultipleGtins: yup.boolean(),
  registrationPurpose_useSearch: yup.boolean(),
  registrationPurpose_downloadDatabase: yup.boolean(),
  registrationPurpose_checkMyGtins: yup.boolean(),
  registrationPurpose_generateCodeQR: yup.boolean(),
  registrationPurpose: yup.mixed().test(registrationPurposeTest),
};

const generateCompanyDataSchema = (t: i18next.TFunction) =>
  yup.object().shape({
    nip: yup
      .string()
      .required(t("form.error.required"))
      .test("Check is nip is valid", t("registration.form.companyData.nip.invalidFormat"), (nip) =>
        NipValidator().validate(nip),
      ),
    name: yup
      .string()
      .required(t("form.error.required"))
      .matches(regexps.ADDRESS, `${t("validation.errors.bad.data")}`)
      .max(130, `${t("registration.form.lengthValidationMax")} ${130}`),
    street: yup
      .string()
      .required(t("form.error.required"))
      .matches(regexps.ADDRESS, `${t("validation.errors.bad.data")}`)
      .max(130, `${t("registration.form.lengthValidationMax")} ${130}`),
    city: yup
      .string()
      .required(t("form.error.required"))
      .matches(regexps.CITY, `${t("validation.errors.bad.data")}`)
      .max(130, `${t("registration.form.lengthValidationMax")} ${130}`),
    postalCode: yup
      .string()
      .required(t("form.error.required"))
      .matches(regexps.POSTAL_CODE, `${t("registration.form.incorrectFormat")}`),
    district: yup.string().required(t("form.error.required")),
    country: yup
      .string()
      .required(t("form.error.required"))
      .max(130, `${t("registration.form.lengthValidationMax")} ${130}`),
    webPage: yup.string().max(130, `${t("registration.form.lengthValidationMax")} ${130}`),

    ...sharedFields,
});

export const generateRegonSchema = (t: i18next.TFunction) =>
  yup.object().shape({
    ...sharedFields,
  })

export default generateCompanyDataSchema;
