import { MessageType } from "components/StatusMesssage/StatusMessage";
import i18n from "../i18n/i18n";

export interface NPWSStatus {
  message: string;
  type: MessageType;
  duration?: number;
}

const Status = () => {
  const t = (value: string) => i18n.t(value);

  const GENERIC_ERROR: NPWSStatus = {
    type: "error",
    message: t("errors.general"),
  };

  const TOO_MANY_REQUESTS: NPWSStatus = {
    type: "error",
    message: "Zbyt dużo zapytań",
  };

  const DATABASE_GENERATE_SUCESS: NPWSStatus = {
    type: "success",
    message: t("database.generate.success"),
  };

  const COMPANY_CREATED: NPWSStatus = {
    type: "success",
    message: t("registration.form.companyData.created"),
  };

  const ACTIVATE_ACCOUNT_ERROR: NPWSStatus = {
    type: "error",
    message: t("activate.account.form.error"),
  };

  const ACTIVATE_ACCOUNT_SUCCESS: NPWSStatus = {
    type: "success",
    message: t("activate.account.form.success"),
  };

  const ACTIVATE_ACCOUNT_ALREADY_ACTIVATED: NPWSStatus = {
    type: "error",
    message: t("activate.account.form.already.activated"),
  };

  const LINK_EXPIRED: NPWSStatus = {
    type: "error",
    message: t("set.password.link.expired"),
  };

  const ALREADY_ACTIVATED: NPWSStatus = {
    type: "error",
    message: t("set.password.link.already.activated"),
  };

  const REGISTRATION_COMPANY_EXISTS: NPWSStatus = {
    type: "error",
    message: t("registration.form.personal.error.entity.registered"),
  };

  const UNFINISHED_COMPANY_REGISTRATION: NPWSStatus = {
    type: "error",
    message: t("registration.form.personal.error.notFinished"),
  };

  const NO_PRODUCTS: NPWSStatus = {
    type: "error",
    message: t("catalog.error.noProduct"),
  };

  const PASSWORD_RESET: NPWSStatus = {
    type: "success",
    message: t("reset.password.success.message"),
  };

  const PASSWORD_RESETED: NPWSStatus = {
    type: "success",
    message: t("reset.password.changed"),
  };

  const ONLY_FOR_ACTIVE_ACCOUNTS_ERROR: NPWSStatus = {
    type: "error",
    message: t("upload.data.error.activeMembership"),
  };

  const FILE_UPLOADED: NPWSStatus = {
    type: "success",
    message: t("upload.data.form.success"),
  };

  const INVITE_USER_SUCCESS: NPWSStatus = {
    type: "success",
    message: t("users.invite.success"),
  };

  const MEMBERSHIP_DELETED: NPWSStatus = {
    type: "success",
    message: t("users.membership.deleted"),
  };

  const ACCESS_DENIED: NPWSStatus = {
    type: "error",
    message: t("permissions.access_denied"),
  };

  const USER_PERMISSION_CHAGE_SUCCESS: NPWSStatus = {
    type: "success",
    message: t("users.permissionChange.success"),
  };

  const USER_PERMISSION_NOT_CHANGED: NPWSStatus = {
    type: "warning",
    message: t("users.permissionChange.failure"),
  };

  const LOGIN_FAILED: NPWSStatus = {
    type: "error",
    message: t("form.login.failed"),
  };

  const RESET_PASSWORD_INVALID_EMAIL: NPWSStatus = {
    type: "error",
    message: t("reset.password.form.invalid.email"),
  };

  const UPDATE_USER_DATA_SUCCESS: NPWSStatus = {
    type: "success",
    message: t("user.data.update.success"),
  };

  const GENERATE_ISSUE_FILE_SUCCESS: NPWSStatus = {
    type: "success",
    message: t("repository.issueFile.success"),
  };

  const UPDATE_DATABASE_ACCESS_SUCCESS: NPWSStatus = {
    type: "success",
    message: t("company.database.access.update.success"),
  };

  const CMS_SAVE_SUCCESS: NPWSStatus = {
    type: "success",
    message: t("cms.save.success"),
  };

  const REMOVE_LAST_COMPANY_REPRESENTATIVE: NPWSStatus = {
    type: "error",
    message: t("user.error.removeLastCompanyRepresentative"),
  };

  const WRONG_OLD_PASSWORD: NPWSStatus = {
    type: "error",
    message: t("changePassword.wrong.oldPassword"),
  };

  const PASSWORD_CHANGED: NPWSStatus = {
    type: "success",
    message: t("userData.passwordChanged"),
  };

  const USER_ALREADY_INVITED: NPWSStatus = {
    type: "error",
    message: t("usersList.error.alreadyInvited"),
  };

  const DELETE_LAST_USER_WITH_REPRESENTATION_ERROR: NPWSStatus = {
    type: "error",
    message: t("users.delete.lastRepresentative.error"),
  };

  const CHANGE_PERMISSION_LAST_USER_WITH_REPRESENTATION_ERROR: NPWSStatus = {
    type: "error",
    message: t("users.changePermission.lastRepresentative.error"),
  };

  const NO_LIMIT_FOR_GENERATE_DATABASE: NPWSStatus = {
    type: "error",
    message: 'Twój pakiet usług nie umożliwia pobierania bazy danych. Zamów dostęp w zakładce "Cennik"',
  };
  const NO_LIMIT_FOR_VERIFY: NPWSStatus = {
    type: "error",
    message: t("status.noLimitOrFreeTierVerify"),
  };
  const FILE_ALREADY_PROCESSING: NPWSStatus = {
    type: "warning",
    message: t("status.fileAlreadyInProcessing"),
  };
  const DB_ALREADY_PROCESSING: NPWSStatus = {
    type: "warning",
    message: t("status.dbAlreadyInProcessing"),
  };

  const SUBSCRIPTION_LOGIN_TO_ORDER: NPWSStatus = {
    type: "warning",
    message: t("subscription.order.loginRequired"),
  };
  const SUBSCRIPTION_ORDER_SUCCESS: NPWSStatus = {
    type: "success",
    message: t("subscription.order.success"),
  };

  const OVERLIMIT_DOWNLOAD_DB: NPWSStatus = {
    type: "error",
    message: 'Wybrana ilość produktów przekracza limit w Twoim pakiecie. Ogranicz wyniki wyszukiwania lub zwiększ pakiet.'
  }

  const NO_DOWNLOAD_FOR_THIS_PACKAGE: NPWSStatus = {
    type: "error",
    message: 'Twój pakiet usług nie umożliwia pobierania bazy danych. Zamów dostęp w zakładce "Cennik"'
  }

  return {
    GENERIC_ERROR,
    TOO_MANY_REQUESTS,
    DATABASE_GENERATE_SUCESS,
    COMPANY_CREATED,
    ACTIVATE_ACCOUNT_ERROR,
    LINK_EXPIRED,
    ACTIVATE_ACCOUNT_SUCCESS,
    REGISTRATION_COMPANY_EXISTS,
    NO_PRODUCTS,
    PASSWORD_RESETED,
    ONLY_FOR_ACTIVE_ACCOUNTS_ERROR,
    FILE_UPLOADED,
    INVITE_USER_SUCCESS,
    PASSWORD_RESET,
    MEMBERSHIP_DELETED,
    ACCESS_DENIED,
    USER_PERMISSION_CHAGE_SUCCESS,
    USER_PERMISSION_NOT_CHANGED,
    LOGIN_FAILED,
    RESET_PASSWORD_INVALID_EMAIL,
    UNFINISHED_COMPANY_REGISTRATION,
    ALREADY_ACTIVATED,
    ACTIVATE_ACCOUNT_ALREADY_ACTIVATED,
    UPDATE_USER_DATA_SUCCESS,
    GENERATE_ISSUE_FILE_SUCCESS,
    UPDATE_DATABASE_ACCESS_SUCCESS,
    CMS_SAVE_SUCCESS,
    REMOVE_LAST_COMPANY_REPRESENTATIVE,
    WRONG_OLD_PASSWORD,
    PASSWORD_CHANGED,
    USER_ALREADY_INVITED,
    DELETE_LAST_USER_WITH_REPRESENTATION_ERROR,
    CHANGE_PERMISSION_LAST_USER_WITH_REPRESENTATION_ERROR,
    NO_LIMIT_FOR_GENERATE_DATABASE,
    NO_LIMIT_FOR_VERIFY,
    FILE_ALREADY_PROCESSING,
    DB_ALREADY_PROCESSING,
    SUBSCRIPTION_LOGIN_TO_ORDER,
    SUBSCRIPTION_ORDER_SUCCESS,
    OVERLIMIT_DOWNLOAD_DB,
    NO_DOWNLOAD_FOR_THIS_PACKAGE,
  };
};

const NPWSStatus = Status();

export default NPWSStatus;
