import React from "react";
import { useTranslation } from "react-i18next";

interface LimitsLeftComponentProps {
  company: any;
}

const getVerboseSubscription = (subscription: string) => {
  const VERBOSES: { [key: string]: string } = {
    free: "Starter",
    monthly: "Basic (miesięczny limitowany)",
    yearly_limited: "Pro (roczny limitowany)",
    yearly_unlimited: "Unlimited (roczny nielimitowany)",
    yearlyLimited: "Pro (roczny limitowany)",
    yearlyUnlimited: "Unlimited (roczny nielimitowany)",
  };
  return VERBOSES[subscription] || subscription;
};

const normalizeSubscriptionName = (subscription: string) => {
  if (subscription === "yearly_unlimited") return "yearlyUnlimited";
  if (subscription === "yearly_limited") return "yearlyLimited";
  return subscription;
};

const formatNumber = (value: number | string) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

const LimitsLeftComponent: React.FC<LimitsLeftComponentProps> = ({ company }) => {
  const { t } = useTranslation();

  // Destructure subscription-related data from subscriptionDetails.
  // Note: 'left' is replaced with 'counters'.
  const {
    freeSubscriptionYear,
    beginSubscriptionDt,
    endSubscriptionDt,
    name: subscriptionName,
    counters,
    packages,
  } = company.subscriptionDetails;

  // Check if the active package is free.
  const isFreePackage = subscriptionName === "free";

  const packageKey = isFreePackage ? "free" : normalizeSubscriptionName(subscriptionName);
  const activePackageLimits = packages[packageKey];

  // Determine active status based on the subscription period.
  const getActive = () => {
    if (beginSubscriptionDt && endSubscriptionDt) {
      const begin = new Date(beginSubscriptionDt);
      const end = new Date(endSubscriptionDt);
      const now = new Date();
      return begin <= now && now <= end ? (
        <span className="main__detail font-size-medium" style={{ color: "green" }}>
          {t("company.limits.active")}
        </span>
      ) : (
        <span className="main__detail font-size-medium" style={{ color: "red" }}>
          {t("company.limits.noActive")}
        </span>
      );
    }
    return null;
  };

  return (
    <>
      <h4>
        <b>Aktywny pakiet usług:</b>
      </h4>

      {/* Free / Starter Package Section */}
      <div className="font-size-medium mb-0">
        <div>
          <span className="main__detail font-size-medium">
            {t("company.limits.starter.title")}
          </span>
          <span className="main__detail font-size-medium" style={{ color: "green" }}>
            {t("company.limits.active")}
          </span>
          <div className="font-size-medium">
            {t("company.limits.period")} {t("from")} {freeSubscriptionYear}-01-01 {t("to")}{" "}
            {freeSubscriptionYear}-12-31
          </div>
        </div>
        <div className="font-size-medium">{t("company.limits.usage")}</div>
        <div className="font-size-medium mb-0">
          <ul className="mb-0">
            <li>
              {t("company.limits.verification")} {formatNumber(counters.freeVerified)} |{" "}
              {t("company.limits.limit")} {formatNumber(packages.free.verify)}{" "}
              {t("company.limits.products")}
            </li>
            <li>
              {t("company.limits.downloadDB")} {t("company.limits.na")}
            </li>
            <li>
              {t("company.limits.catalog.browse")}{" "}
              {packages.free.ui ? packages.free.ui : t("company.limits.noLimit")}
            </li>
          </ul>
        </div>
      </div>

      {/* Active (Non-Free) Subscription Package Section */}
      {!isFreePackage && (
        <div className="font-size-medium mt-line">
          <div>
            <span className="main__detail font-size-medium">
              {getVerboseSubscription(subscriptionName)}:
            </span>
            {getActive()}
            {beginSubscriptionDt && endSubscriptionDt && (
              <div className="font-size-medium">
                {t("company.limits.period")} {t("from")} {beginSubscriptionDt} {t("to")}{" "}
                {endSubscriptionDt}
              </div>
            )}
          </div>
          <div className="font-size-medium">{t("company.limits.usage")}</div>
          <div className="font-size-medium">
            <ul>
              <li>
                {t("company.limits.verification")} {formatNumber(counters.verified)} |{" "}
                {t("company.limits.limit")} {formatNumber(activePackageLimits.verify)}{" "}
                {t("company.limits.products")}
              </li>
              <li>
                {t("company.limits.downloadDB")}{" "}
                {formatNumber(counters.api + counters.csv)} |{" "}
                {t("company.limits.limit")} {formatNumber(activePackageLimits.api)}{" "}
                {t("company.limits.products")}
              </li>
              <li>
                  {t("company.limits.catalog.browse")}{" "}
                  {(!activePackageLimits.ui || activePackageLimits.ui === "∞")
                    ? "bez ograniczeń"
                    : activePackageLimits.ui}
              </li>
            </ul>
          </div>
        </div>
      )}

      <p className="font-size-medium mb-0">
        {t("company.limits.pricing")} <a href="/pricing">{t("navbar.subscriptions")}</a>
      </p>

      <p className="text-center font-size-medium mt-line">
        <img src="/icons/tip-icon@2x.png" height="20" className="image" />{" "}
        {t("company.limits.buyMore")}{" "}
        <a href="https://www.eprodukty.gs1.pl/pricing">eprodukty.gs1.pl/pricing</a>
      </p>
    </>
  );
};

export default LimitsLeftComponent;
