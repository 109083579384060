/*
https://gs1.github.io/2d-barcode-generator/
https://digital-link.evrythng.com/generator
https://github.com/evrythng/digital-link.js
*/

import React from "react";
import { useTranslation } from "react-i18next";
import Input from "components/Inputs/Input";
import { useForm } from "react-hook-form";
import generateGenerator2DSchema from "./Generator2DValidation";
import GS1APIClient from "clients/GS1APIClient";
import { useState } from "react";
import DynamicForm from "./DynamicForm";
import Select, { ValueType, OptionsType } from "react-select";
import useAuthentication from "hooks/useAuthentication";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

interface Filters {
  companies: string[];
  statuses: string[];
  errors: string[];
  sources: string[];
  showAbandoned: boolean;
}

const initFilters: Filters = {
  companies: [],
  statuses: [],
  errors: [],
  sources: [],
  showAbandoned: false,
};

interface FieldOption {
  label: string;
  value: string;
}

const Generator2d = () => {
  const { activeMembership } = useAuthentication();
  const [imageUrl, setImageUrl] = useState("");
  const [isLoadedFirstTime, setIsLoadedFirstTime] = useState(false);
  const [savedResponse, setResponse] = useState<any>({});
  const [savedFormState, setFormState] = useState<any>({});

  const [isLoading, setLoading] = useState(false);
  const [isGS1DownloadLoading, setGS1DownloadLoading] = useState(false);
  const [isQRDownloadLoading, setQRDownloadLoading] = useState(false);
  const [selectedQRDownloadValue, setSelectedQRDownloadValue] = useState("");
  const [selectedGS1DownloadValue, setSelectedGS1DownloadValue] = useState("");
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const gtinNumberQs = queryParams.get("gtin_number");

  const [filters, setFilters] = useState<Filters>(initFilters);

  const { t } = useTranslation();
  const form = useForm({
    validationSchema: generateGenerator2DSchema(t),
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const { handleSubmit, register, setError, formState } = form;

  function getValuesFromMultiselect(options: { [key: string]: string }[]) {
    return options.map((company) => company.value);
  }

  function onStatusChange(values: any) {
    const statuses = getValuesFromMultiselect(values);
    setFilters({ ...filters, statuses });
  }

  const b64toBlob = (b64Data: any, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    console.log("start loading");

    try {
      const response = await GS1APIClient.generate2d(data);
      let dataResponse: any = response.data;
      if (dataResponse && dataResponse.errors) {
        Object.keys(dataResponse.errors).forEach((key) => {
          setError(key, "manual", dataResponse.errors[key]);
        });
        setIsLoadedFirstTime(false);
      } else {
        setIsLoadedFirstTime(true);
        setFormState(data);
        setResponse(response.data);
      }
    } catch (error) {
      console.error("Error during API call", error);
      // Handle error appropriately
    } finally {
      console.log("stop loading");
      setLoading(false); // End loading
      setSelectedGS1DownloadValue("");
      setSelectedQRDownloadValue("");
    }
  };

  const downloadOptions: FieldOption[] = [
    { label: "AI", value: "ai" },
    { label: "PDF", value: "pdf" },
    { label: "PNG", value: "png" },
    { label: "SVG", value: "svg" },
    { label: "PS", value: "ps" },
  ];

  const downloadSelectCustomStyles = {
    container: (provided: any) => ({
      ...provided,
      width: "115px",
    }),
    control: (provided: any) => ({
      ...provided,
      minHeight: "30px",
      height: "30px",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      height: "30px",
      padding: "0 6px",
    }),
    input: (provided: any) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      height: "30px",
    }),
  };

  const handleSelectDownloadQRChange = (event: any) => {
    setSelectedQRDownloadValue(event.value);
  };

  const handleSelectDownloadGS1Change = (event: any) => {
    setSelectedGS1DownloadValue(event.value);
  };

  const handleDownloadFieldSelection = async (e: React.FormEvent<HTMLFormElement>, type: string) => {
    e.preventDefault();
    if (type === "qrcode") {
      setQRDownloadLoading(true);
    }
    if (type === "datamatrix") {
      setGS1DownloadLoading(true);
    }
    const format = type == "qrcode" ? selectedQRDownloadValue : selectedGS1DownloadValue;
    console.log(`Download: ${type}, ${format}`);
    try {
      await GS1APIClient.download2d(type, format, savedFormState);
    } catch (error) {
      console.error("Download error", error);
      // Handle error appropriately
    } finally {
      if (type === "qrcode") {
        setQRDownloadLoading(false);
      }
      if (type === "datamatrix") {
        setGS1DownloadLoading(false);
      }
    }
  };

  return (
    <>
      <div className="position-relative page-banner">
        <div className="w-100 position-absolute img-hover">
          <div className="container">
            <h1 className="first">Generator kodów 2D (wersja demonstracyjna) <span style={{fontSize: "1.2rem"}}>Wprowadź dane i wygeneruj testowy kod GS1 QR lub GS1 DataMatrix</span></h1>
          </div>
        </div>
        <div className="position-relative">
          <img className="w-100" src="assets/generator_2d_baner.png"></img>
          <div className="overlay"></div>
        </div>
      </div>

      <div className="generator2d-main-view container px-0">
        <div className="row">
          <div className="requiredFieldsLabel">Pole obowiązkowe</div>
          <form onSubmit={handleSubmit(onSubmit)} className="row">
            <div className="col-4">
              <Input
                labelText="(01) Numer GTIN (kod EAN)"
                customLabelClassName="mb-0"
                name="n01"
                placeholder=" "
                value={gtinNumberQs ?? ""}
                error={form.errors.n01}
                reference={register}
              />
            </div>
            <div className="col-4"></div>
            <div className="col-4"></div>
            <div className="optionalFieldsLabel container">Pola opcjonalne</div>
            <div className="col-4">
              <Input
                labelText="(10) Numer partii/serii produkcyjnej"
                customLabelClassName="mb-0 font-normal"
                name="n10"
                placeholder=" "
                error={form.errors.n10}
                reference={register}
              />
            </div>
            <div className="col-4">
              <Input
                labelText="(17) Data ważności/spożyć do (RRMMDD)"
                customLabelClassName="mb-0 font-normal"
                placeholder=" "
                name="n17"
                error={form.errors.n17}
                reference={register}
              />
            </div>
            <div className="col-4">
              <Input
                labelText="Adres strony www (dla GS1 Digital Link)"
                customLabelClassName="mb-0 font-normal"
                name="ngs1Resolver"
                placeholder=" "
                error={form.errors.ngs1Resolver}
                reference={register}
              />
            </div>

            <div className="col-12">
              <DynamicForm form={form} />
            </div>

            <div className="col-12 mt-3 d-flex align-items-center justify-content-end">
  <span
    className="text-danger mr-3"
    style={{ fontSize: "0.80rem", lineHeight: 1.2, textAlign: "right" }}
  >
    <b>UWAGA!</b> Ten generator służy wyłącznie do celów demonstracyjnych.
    <br />
    Wygenerowane kody nie są przeznaczone do wdrożenia w produkcji masowej.
  </span>
  <button
    className="btn primary"
    data-testid="searchButton"
    type="submit"
  >
    Wygeneruj kod
  </button>
</div>

</form>
        </div>

        <div className="row my-3">
          <div className="col d-flex flex-column justify-content-center bg-color-white py-1">
            <div
              className="d-flex flex-column align-items-center"
              style={{
                minHeight:
                  isLoadedFirstTime && isLoading ? "500px" : isLoading || !isLoadedFirstTime ? "165px" : "auto",
              }}>
              {isLoading ? (
                <div
                  className="spinner-border text-primary align-self-center"
                  role="status"
                  style={{ width: "50px", height: "50px", marginTop: "40px" }}>
                  <span className="sr-only">Ładowanie...</span>
                </div>
              ) : !isLoadedFirstTime ? (
                <img width="140" height="140" src="/assets/search-laptop-catalog.svg" className="align-self-center" />
              ) : null}
            </div>

            {!isLoading && isLoadedFirstTime && (
              <div className="container generator2d" style={{ margin: "auto" }}>
                <div className="row mt-3" style={{ display: "flex", alignItems: "flex-start" }}>
                  <div className="col-md-2">
                    <span className="title">GS1 DataMatrix:</span>
                    <br />
                    <img
                      src={
                        savedResponse.gs1datamatrix &&
                        URL.createObjectURL(b64toBlob(savedResponse.gs1datamatrix.image, "image/svg+xml"))
                      }
                    />
                    <p>{savedResponse.gs1datamatrix && savedResponse.gs1datamatrix.desc}</p>
                  </div>
                  <div className="col-md-6">
                    <form onSubmit={(selectedOption) => handleDownloadFieldSelection(selectedOption, "datamatrix")}>
                      <div className="downloadSection">
                        <Select
                          options={downloadOptions}
                          placeholder="format"
                          onChange={handleSelectDownloadGS1Change}
                          styles={downloadSelectCustomStyles}
                        />
                        <button
                          disabled={!selectedGS1DownloadValue || isGS1DownloadLoading}
                          className="downloadButton btn border bg-color-white txt-color-dusty-orange"
                          type="submit">
                          {isGS1DownloadLoading ? (
                            <div className="spinner-border text-primary" role="status">
                              <span className="sr-only">Ładowanie...</span>
                            </div>
                          ) : (
                            <>
                              <i className="fas fa-download mr-3"></i>
                              {t("Pobierz")}
                            </>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row">
                  <hr />
                </div>

                <div className="row" style={{ display: "flex", alignItems: "flex-start" }}>
                  <div className="col-md-2">
                    <span className="title">GS1 QR:</span>
                    <br />
                    <img
                      src={
                        savedResponse.qrcode &&
                        URL.createObjectURL(b64toBlob(savedResponse.qrcode.image, "image/svg+xml"))
                      }
                    />
                    <p>{savedResponse.qrcode && savedResponse.qrcode.desc}</p>
                  </div>
                  <div className="col-md-6">
                    <form onSubmit={(selectedOption) => handleDownloadFieldSelection(selectedOption, "qrcode")}>
                      <div className="downloadSection">
                        <Select
                          options={downloadOptions}
                          placeholder="format"
                          onChange={handleSelectDownloadQRChange}
                          styles={downloadSelectCustomStyles}
                        />
                        <button
                          disabled={!selectedQRDownloadValue || isQRDownloadLoading}
                          className="downloadButton btn border bg-color-white txt-color-dusty-orange"
                          type="submit">
                          {isQRDownloadLoading ? (
                            <div className="spinner-border text-primary" role="status">
                              <span className="sr-only">Ładowanie...</span>
                            </div>
                          ) : (
                            <>
                              <i className="fas fa-download mr-3"></i>
                              {t("Pobierz")}
                            </>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="row">
                  <div className="container d-flex flex-column gs1DigitalLink">
                    GS1 Digital Link URI: <br />
                    {savedResponse.qrcode.qr_url}
                  </div>
                </div>
              </div>
            )}

            <div className="text-justify mx-auto my-3 font-size-base">
              <hr />
              <p>
                <strong>Co to jest generator kodów 2D?</strong>
                <br />
                Generator kodów 2D (dwuwymiarowych) jest narzędziem umożliwiającym utworzenie{" "}
                <b>kodu GS1 QR z Digital Link</b> lub <b>GS1 DataMatrix</b> dla numeru GTIN produktu oraz dodatkowych
                informacji, np. daty ważności produktu czy numeru partii.
              </p>
              <p>
                Generator jest{" "}
                <b style={{ color: "red" }}>
                <u>narzędziem demonstracyjnym</u>
                </b>{" "}
                dedykowanym głównie dla firm, które planują wdrożyć stosowanie kodów dwuwymiarowych w miejsce
                funkcjonującego kodu EAN-13. GS1 Polska nie ponosi odpowiedzialności za ewentualne błędy w działaniu
                generatora, patrz{" "}
                <a target="_blank" href="/terms">
                  Regulamin
                </a>
                .
              </p>
              <p>
                <strong>Podstawowe informacje dla użytkownika</strong>
                <br />
                Generowanie kodów 2D jest usługą bezpłatną, dostępną dla użytkowników zarejestrowanych i zalogowanych na
                platformie eProdukty. Tworzenie GS1 DataMatrix oraz GS1 QR z Digital Link jest możliwe{" "}
                <b>tylko dla aktywnych numerów GTIN*</b> nadanych przez dowolną Organizację Krajową GS1. Aktywny numer
                GTIN oznacza, że:
                <ul>
                  <li>jest objęty aktywną licencją w Systemie GS1,</li>
                  <li>
                    ma uzupełnione dane produktowe w Rejestrze GS1 (np. MojeGS1.pl) przez firmę uprawnioną do nadawania
                    danego nr GTIN.
                  </li>
                </ul>
                *System nie wygeneruje kodu dla numeru GTIN, którego licencja wygasła lub jest aktywna, ale produkt nim
                oznaczony został wycofany z produkcji.
              </p>
              <p>
                W kodzie 2D użytkownik ma możliwość zakodowania <b>numeru GTIN</b> oraz dodatkowych informacji o
                produkcie korzystając z <b>Identyfikatorów Zastosowań GS1 (IZ)</b>. Dodatkowo, system pozwala utworzyć
                GS1 Digital Link - dotyczy wyłącznie kodu GS1 QR z Digital Link.
              </p>
              <p>
                <strong>Jak korzystać z generatora?</strong>
                <br />
                1. Określ, które informacje o produkcie chcesz przedstawić w kodzie i wypełnij pola:
                <br />
                a) obowiązkowe: numer GTIN (kod EAN) jest podstawowym parametrem,
                <br />
                b) opcjonalne: dodatkowe informacje o produkcie w odpowiednim formacie danych (
                <a
                  target="_blank"
                  href="https://gs1pl.org/app/uploads/2023/06/Identyfikatory_Zastosowania_GS1_2023.pdf">
                  wytyczne dla Identyfikatorów Zastosowań
                </a>
                )<br />
                2. Kliknij „Wygeneruj kod”.
                <br />
                3. Pobierz plik graficzny z kodem (dostępne są formaty rastrowe oraz wektorowe).
              </p>
              <p>
                <strong>Dowiedz się więcej o kodach 2D GS1:</strong>
                <br />
                &rarr;{" "}
                <a target="_blank" href="https://gs1pl.org/standardy/gromadzenie/kody-kreskowe/gs1-datamatrix/">
                  GS1 DataMatrix
                </a>
                <br />
                &rarr;{" "}
                <a target="_blank" href="https://gs1pl.org/standardy/gromadzenie/kody-kreskowe/gs1-qr/">
                  GS1 QR
                </a>
                <br />
                &rarr;{" "}
                <a target="_blank" href="https://gs1pl.org/standardy/wspoldzielenie/gs1-digital-link/">
                  GS1 Digital Link
                </a>
                <br />
                &rarr;{" "}
                <a target="_blank" href="https://gs1pl.org/standardy/gromadzenie/kody-2d/">
                  Kod 2D nowej generacji
                </a>
              </p>
              <p>
                <strong>Jesteś zainteresowany wdrożeniem kodów 2D w swojej firmie?</strong>
                <br />
                Skontaktuj się z nami i weź udział w pilotażu wdrożeń kodów 2D:
                <br />
                Magdalena Bednarkiewicz:{" "}
                <a href="mailto:magdalena.bednarkiewicz@gs1pl.org">magdalena.bednarkiewicz@gs1pl.org</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Generator2d;
