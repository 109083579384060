import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import useAuthentication from "hooks/useAuthentication";
import { useDispatch } from "react-redux";
import { getProductsCounter } from "store/thunk/DataThunk";
import { CmsService } from "services";
import { CmsInterface } from "../../interfaces/CmsInterface";
import { Markup } from "interweave";

declare global {
  interface Window {
    refreshFsLightbox: () => void;
  }
}

export const HomePageContainer: React.FC<CmsInterface> = ({ preview, cmsData }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [counters, setCounters] = useState({ productCount: "", productCountFrom7Days: "" });
  const { activeMembership } = useAuthentication();

  const [cmsDataState, setCmsDataState] = useState(cmsData);

  async function getCounters() {
    const {
      data: { productCount, productCountFrom7Days },
    }: any = await dispatch(getProductsCounter());
    setCounters({ productCount, productCountFrom7Days });
  }

  async function getPageContent() {
    const response = await CmsService.getPageContent("home-page", activeMembership?.id);
    setCmsDataState(response.data);
  }

  useEffect(() => {
    if (!cmsDataState) {
      getPageContent();
    }

    setCmsDataState(cmsData);
    getCounters();

    const tryRefreshFsLightbox = () => {
      if (typeof window.refreshFsLightbox === "function") {
        window.refreshFsLightbox();
      } else {
        setTimeout(tryRefreshFsLightbox, 1000);
      }
    };

    setTimeout(tryRefreshFsLightbox, 500);
  }, [cmsData]);

  const homepageButton = (to: string, label: string) => {
    return (
      <button
        className="btn primary font-size-medium d-flex align-self-start"
        onClick={() => {
          history.push(to);
        }}
        type="button">
        {label}
      </button>
    );
  };

  const Hero: React.FC = () => {
    return (
      <div className="hero">
        <div className="hero__row--first">
          <div className="hero__column--first">
            <div className="hero__picture--back" />
            <div className="hero__picture--front" />
            <div className="container">
              <div className="hero__description">
                <div className="hero__description__row">
                  <div className="hero__description__row__column">
                    <h1 className="hero__description__row__column__header">
                      eProdukty – źródło wiarygodnych danych produktowych z całego świata
                    </h1>
                    <ol>
                      <li>baza kodów EAN / numerów GTIN</li>
                      <li>baza produktowa</li>
                    </ol>
                    {!activeMembership && homepageButton("/registration", t("homepage.cta.register"))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hero__row--second">
          <div className="hero__column--second">
            <h3 className="hero__column--second__header">
              <div className="products-container">
                <div className="box">
                  <div className="number">{counters.productCount.toLocaleString()}</div>
                  <div className="text">
                    produktów
                    <br />w polskiej Bazie GS1
                  </div>
                </div>
                <div className="box">
                  <div className="number">{counters.productCountFrom7Days.toLocaleString()}</div>
                  <div className="text">
                    nowych produktów
                    <br />z ostatniego tygodnia
                  </div>
                </div>
                <div className="box">
                  <div className="number">600+ milionów</div>
                  <div className="text">
                    produktów
                    <br />w Globalnym Rejestrze GS1
                  </div>
                </div>
              </div>
            </h3>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="row d-flex mt-n4">
      <Hero />
      <div className="col-12 static-content">
        <div className="first-chapter container pt-5">
          <div className="first-chapter__row">
            <div className="cms-content ck-content">
              {cmsDataState ? <Markup content={cmsDataState && cmsDataState.content} /> : <></>}
            </div>
          </div>
        </div>

        <div className="values-wrapper pt-5 pb-2 mb-5">
          <div className="values container values-wrapper__container">
            <div className="row my-5">
              <div className="pill">
                <img
                  src="/assets/v3/homepage/beneficients-icons/1_platformy_marketplace.png"
                  className="pill--image"
                  height={140}
                />
                <h3 className="pill--description">Platformy marketplace</h3>
              </div>

              <div className="pill">
                <img
                  src="/assets/v3/homepage/beneficients-icons/2_sieci_handlowe.png"
                  className="pill--image"
                  height={140}
                />
                <h3 className="pill--description">Sieci handlowe</h3>
              </div>

              <div className="pill">
                <img
                  src="/assets/v3/homepage/beneficients-icons/3_porownywarki_cenowe.png"
                  className="pill--image"
                  height={140}
                />
                <h3 className="pill--description">Porównywarki cenowe</h3>
              </div>

              <div className="pill">
                <img
                  src="/assets/v3/homepage/beneficients-icons/4_hurtownie_magazyny.png"
                  className="pill--image"
                  height={140}
                />
                <h3 className="pill--description">Hurtownie i magazyny</h3>
              </div>
            </div>

            <div className="row my-5">
              <div className="pill">
                <img
                  src="/assets/v3/homepage/beneficients-icons/5_katalogi_produktowe.png"
                  className="pill--image"
                  height={140}
                />
                <h3 className="pill--description">Katalogi produktowe</h3>
              </div>

              <div className="pill">
                <img
                  src="/assets/v3/homepage/beneficients-icons/6_systemy_it.png"
                  className="pill--image"
                  height={140}
                />
                <h3 className="pill--description">Systemy IT do zarządzania produktami</h3>
              </div>

              <div className="pill">
                <img
                  src="/assets/v3/homepage/beneficients-icons/7_narzedzia_do_integracji.png"
                  className="pill--image"
                  height={140}
                />
                <h3 className="pill--description">Narzędzia do integracji sklepów internetowych i marketplace</h3>
              </div>

              <div className="pill">
                <img
                  src="/assets/v3/homepage/beneficients-icons/8_aplikacje_mobilne_webowe.png"
                  className="pill--image"
                  height={140}
                />
                <h3 className="pill--description">Aplikacje mobilne i webowe</h3>
              </div>
            </div>
          </div>
        </div>
        {!activeMembership && (
          <>
            <div className="position-relative page-banner footing" style={{ marginBottom: "-1rem" }}>
              <div className="w-100 position-absolute img-hover">
                <div className="container">
                  <div className="footing__description__row">
                    <div className="footing__description__column">
                      <h2 className="footing__description__header">
                        {`${t("homepage.cta.register")} ${t("homepage.cta.register.more")}`}
                      </h2>
                      <div className="d-flex align-self-center">
                        {homepageButton("/registration", t("homepage.cta.register"))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="position-relative">
                <img className="w-100" src="/assets/footing@1x.webp"></img>
                <div className="overlay"></div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HomePageContainer;
